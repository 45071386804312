import * as React from 'react';

import { Helmet } from 'react-helmet';

// Redirect to app.sequincard.com
const RedirectPage = () => {
  React.useEffect(() => {
    window.location.href = 'https://app.sequincard.com/';
  }, []);

  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
};

export default RedirectPage;
